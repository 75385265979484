// Reminder: Any local imports need to use relative paths
// Otherwise they'll be interpreted as package names
// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// I don't really use Action Cable so disable this for now
//import "./channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
